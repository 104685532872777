import styled from 'styled-components';
import theme from '../../theme';
import arrow from '../../../static/images/arrow_right.svg';

export const CenteredTitle = styled.div`
  h1 {
    margin: 6rem auto;
    margin-bottom: 3rem;
    padding-top: 2rem;
    font-size: 28px;
    text-align: center;
    color: black;
    ${(props) =>
  props.colorTitle &&
  `
    color: #074788;
    font-weight: bold;
    font-family: adobe-garamond-pro, sans-serif;
  `};
  }
  @media (min-width: 400px) {
    h1 {
      font-size: 32px;
    }
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 45px;
    }
  }
`

export const Title = styled.div`
  .main_title {
    font-family: futura-pt, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  ${props => props.indexHero && `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `};
  ${props => !props.indexHero && `
  height: 100%;
  .main_title {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  `};
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgb(11, 106, 204);
  background: var(--theme-background-gradient-header);
  h1 {
    font-family: futura-pt, sans-serif;
    font-weight: bold;
    font-size: 28px;
    text-align: left;
    padding-left: 50px;
    max-width: 80%;
  }
  p {
    color: white;
    text-align: center;
    max-width: 900px;
  }
  .main_title a {
    margin-left: 50px;
    margin-bottom: 20px;
    display: block;
    text-align: center;
    background: #fff;
    border-radius: 22px;
    color: #074788;
    text-decoration: none;
    font-size: 17px;
    margin-top: 20px;
    padding: 10px 30px;
    font-weight: bold;
  }
  a.contact_us {
    ${props => props.indexHero && `align-self: center;`};
    max-width: 90px;
    display: block;
    text-align: left;
    background: #fff;
    border-radius: 22px;
    color: var(--base-color);
    text-decoration: none;
    font-size: 17px;
    margin-top: 20px;
    padding: 10px 30px;
    font-weight: bold;
  }
  @media (min-width: 401px) {
    h1 {
      font-size: 32px;
    }
    .main_title h1 {
      font-size: 32px;
      text-align: center;
      padding-left: 0px;
    }
    .main_title a {
      margin-left: 10px;
    }
    a.contact_us {
      /*margin-left: 50px;*/
      margin-bottom: 20px;
    }
  }
  @media (min-width: 768px) {
    h1 {
      font-size: 41px;
    }
    .main_title h1 {
      margin-bottom: 5px;
    }
    .main_title p {
      align-self: center;
      margin: 0px;
    }
  }
  @media (min-width: 960px) {
    width: ${(props) => props.width || "35%"};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main_title h1 {
      font-size: 48px;
      padding: 0 10px;
      margin: 0px auto 25px auto;
    }

    a.contact_us {
      margin-left: 10px;
      margin-bottom: 20px;
      align-self: center;
    }
    .main_title a {
      align-self: center;
      margin-left: 10px;
      max-width: 240px;
    }
  }
  @media (min-width: 1300px) {
    .main_title h1 {
      font-size: 60px;
    }
  }
  @media (max-width: 400px) {
    h1 {
      text-align: center;
      padding-left: 0px;
    }
    .main_title a {
      margin-left: 0px;
    }
    a.contact_us {
      margin-left: 10px;
      margin-bottom: 20px;
    }
  }

`;

export const HelperDiv = styled.div`
  margin-bottom: 50px;
  ul {
    padding: 0;
    list-style-type: none;
    li {
      display: block;
      text-align: center;
      font-family: Lato, sans-serif;
      font-size: 20px;
      line-height: 1.3;
      padding: 10px 50px;
    }
  }
  ul li:before {
    color: var(--bullet-point-color);
    content: '•';
    padding-right: 5px;
  }
`;

export const P1 = styled.p`
  font-size: ${theme.fontSizeBody};
  ${(props) => props.customCSS && props.customCSS}
`;

export const P2 = styled.p`
  font-size: ${theme.fontSizeS};
  line-height: ${theme.lineHeightS};
  ${(props) => props.customCSS && props.customCSS}
`;

export const P1ReactMarkdownWrapper = styled.div`
  p {
    font-size: ${theme.fontSizeBody};
    ${(props) => props.customCSS && props.customCSS}
  }
`;
export const P2ReactMarkdownWrapper = styled.div`
  p {
    font-size: ${theme.fontSizeS};
    line-height: ${theme.lineHeightS};
    ${(props) => props.customCSS && props.customCSS}
  }
`;

export const Pinline = styled.p`
  display: inline-block;
  margin: 0 ${theme.marginXS} ${theme.marginXS} 0;
  font-weight: ${theme.fontWeightBold};
  ${(props) => props.customCSS && props.customCSS}
`;

export const Error = styled.p`
  color: ${theme.colors.red};
  font-weight: ${theme.fontWeightSemibold};
  margin-top: 0.75rem;
`;

export const Subtitle1 = styled.h3`
  font-size: 1.16rem;
  line-height: 1.5;
  font-weight: ${theme.fontWeightRegular};
  ${(props) => props.customCSS && props.customCSS}
`;

export const Subtitle2 = styled.h4`
  font-size: 1rem;
  line-height: 1.5;
  font-weight: ${theme.fontWeightBold};
  ${(props) => props.customCSS && props.customCSS}
`;

export const Overline = styled.p`
  font-size: ${theme.fontSizeXS};
  letter-spacing: 0.25em;
  text-transform: uppercase;
  color: ${theme.colors.medium};
  font-weight: ${theme.fontWeightBold};
  margin-bottom: ${theme.marginS};
  ${(props) => props.customCSS && props.customCSS}
`;

export const Caption = styled.p`
  font-size: ${theme.fontSizeXS};
  color: ${theme.colors.medium};
  font-weight: ${theme.fontWeightBold};
  ${(props) => props.customCSS && props.customCSS}
`;

export const LinkCaption = styled.a.attrs({
  tabIndex: '0',
})`
  font-size: ${theme.fontSizeBody};
  color: ${theme.colors.dark};
  font-weight: ${theme.fontWeightRegular};
  line-height: ${theme.lineHeightL};
  opacity: 0.8;
  text-decoration: none;
  cursor: pointer;

  ${(props) => props.customCSS && props.customCSS}
`;

export const LinkPill = styled.a.attrs({
  tabIndex: '0',
})`
  font-weight: ${theme.fontWeightBold};
  background: ${theme.colors.yellow};
  padding: 0 8px;
  border-radius: 20px;
  text-decoration: underline;
  cursor: pointer;
  transition: ${theme.transitionBase};

  &:hover {
    background: #fff;
  }

  ${(props) => props.customCSS && props.customCSS}
`;

export const LinkStrong = styled.a.attrs({
  tabIndex: '0',
})`
  font-weight: ${theme.fontWeightBold};
  text-decoration: none;
  border-bottom: 2px solid ${theme.colors.pageColor};
  cursor: pointer;
  transition: ${theme.transitionBase};

  &:hover {
    border-color: #fff;
  }

  ${(props) => props.customCSS && props.customCSS}
`;

export const LinkArrow = styled(LinkStrong)`
  &:after {
    content: '';
    display: inline-block;
    background: url(${arrow}) no-repeat 50% 50%;
    background-size: cover;
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }
`;

export const BoxTitle = styled.h3`
  font-size: 1.44rem;
  line-height: 1.25;
  font-weight: ${theme.fontWeightBold};
  background: ${theme.colors.yellow};
  padding: ${theme.spacingS} ${theme.spacingL};
  border-radius: ${theme.borderRadiusL};
  font-weight: ${theme.fontWeightBold};

  @media only screen and (min-width: ${theme.breakpoints.tabletLandscape}) {
    font-size: 1.77rem;
    line-height: 1.25;
    text-transform: uppercase;
  }

  ${(props) => props.customCSS && props.customCSS}
`;
